import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"start","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"3"}},[_c(VImg,{attrs:{"src":require('../assets/sab-logo.png')}})],1)],1),_c(VRow,{attrs:{"align":"start","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"2"}},[_c(VForm,{staticClass:"mt-7",attrs:{"autocomplete":"off"}},[_c(VTextField,{ref:"utilisateur",attrs:{"label":_vm.dictionnaire[0],"placeholder":_vm.dictionnaire[0],"outlined":"","autocomplete":_vm.dictionnaire[0],"prepend-icon":"mdi-account","autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.connexion()}},model:{value:(_vm.identifiant),callback:function ($$v) {_vm.identifiant=$$v},expression:"identifiant"}}),_c(VTextField,{attrs:{"outlined":"","label":_vm.dictionnaire[1],"placeholder":_vm.dictionnaire[1],"prepend-icon":"mdi-lock","autocomplete":_vm.dictionnaire[1],"type":"password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.connexion()}},model:{value:(_vm.motdepasse),callback:function ($$v) {_vm.motdepasse=$$v},expression:"motdepasse"}}),_c(VBtn,{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.connexion()}}},[_c(VIcon,[_vm._v("mdi-check")]),_vm._v(_vm._s(_vm.dictionnaire[2]))],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }