
import Vue from "vue"
import axios from "axios"
import { variables } from "@/plugins/variables"

export default Vue.extend({
  name: "Connexion",
  data: () => ({
    identifiant: "",
    motdepasse: "",
    unsubscribe: null as any,
    dictionnaire: [] as string[]
  }),
  methods: {
    connexion() {
      var url = this.$store.state.ajaxurl + "login.php"
      axios
        .get(url, {
          params: {
            action: "CONNEXION",
            utilisateur: this.identifiant,
            motdepasse: this.motdepasse
          }
        })
        .then(response => {
          if (response && response.data.authentification === "oui") {
            this.$store.commit("majConnexion", true)
            this.$store.commit("majLangue", response.data.langue)
            const unUtilsateur = { user: response.data.ut_nomPrenom, ut_id: response.data.ut_id, administrateur: false }
            this.$store.commit("majUtilisateur", unUtilsateur)
            variables.autorisation = response.data.autorisations
            this.$router.push("/TransfertLaitier")
          }
        })
    },
    choixLangue(langue: string) {
      switch (langue) {
        case "FR":
          this.dictionnaire = [
            "Utilisateur", //0
            "Mot de passe", //1
            "Connexion" //2
          ]
          break
        case "AL":
          this.dictionnaire = [
            "Kennung", //0
            "Passwort", //1
            "Anmeldung" //2
          ]
      }
    }
  },
  created() {
    this.choixLangue("AL")
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixLangue(this.$store.state.langue)
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe = null
  }
})
